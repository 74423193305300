import { useMutation, useQuery } from '@apollo/client'
import {
  Core_GeneratePropertyQuestionnaireV2OneTimeLinkResponse,
  Core_GetOrderV2UnauthedResponse,
  Core_OrderV2,
  Core_OrderV2TaskResolutionData_DataOneof_PropertyQuestionnaireCompletedTaskResolutionData,
  Core_OrderV2TaskStatus,
  Core_PropertyQuestionnaireStakeholder,
  Core_PropertyQuestionnaireUtility,
  Core_ValidatedAddress,
  InvestorCreateAddressDocumentDocument,
  InvestorEvictPropertyQuestionnaireV2OneTimeLinkDocument,
  InvestorGeneratePropertyQuestionnaireV2OneTimeLinkDocument,
  InvestorPropertyQuestionnaireGetOrderV2PropertyQuestionnairesDocument,
  InvestorPropertyQuestionnaireGetOrderV2PropertyQuestionnairesUnauthedDocument,
  InvestorPropertyQuestionnaireGetOrderV2PropertyQuestionnairesUnauthedQuery,
  InvestorUpdatePropertyQuestionnaireTaskDocument,
  InvestorUpdatePropertyQuestionnaireTaskUnauthedDocument,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import {
  Dropdown,
  FlowData,
  InputType,
  PageContainer,
  TrackedLink,
  StepConfig,
} from '@flock/shared-ui'
import { Box, Grid, Paper, styled, Typography } from '@mui/material'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { navigate } from 'gatsby'
import {
  formatAddressString,
  formatCityStateZip,
  getAddressStreet,
} from '@flock/utils'
import { useSnackbar } from '@flock/flock-component-library'
import { RouteComponentProps } from '@reach/router'
import {
  FLOCK_INVESTOR_URL,
  FLOCK_LANDING_URL,
  MONTHS,
  ORDERS_URL,
  SHARE_PROPERTY_QUESTIONNAIRE_URL,
} from '../../../constants'
import PageWrapper from '../../SharedComponents/PageWrapper'
import FormStepComponent, {
  FormStepComponentProps,
  InjectedFormStepProps,
} from '../FormStepComponent'
import {
  PropertyQuestionnaireData,
  PropertyQuestionnaireFunctions,
} from './propertyQuestionnaireTypes'
import StepperForm from '../StepperForm'
import LoadingCardPage from '../../SharedComponents/LoadingCardPage'
import ErrorCardPage from '../../SharedComponents/ErrorCardPage'
import ChangeUnitModal from './ChangeUnitModal'
import {
  flattenPropertyQuestionnaireMiscData,
  flattenPropertyQuestionnaireUnitData,
} from './api'

const injectProps = (stepName: string) => (flowData: FlowData) => ({
  flowData,
  stepData: flowData[stepName] || {},
})

const basicProps = (stepName: string) => ({
  stepName,
  component: FormStepComponent,
  injectProps: injectProps(stepName),
})

const progressDisplaySteps = [
  {
    label: 'Lease & Property',
    value: 'leaseAndProperty',
  },
  {
    label: 'HOA & Utilities',
    value: 'hoaAndUtilities',
  },
]

const questionnaireMiscellaneousProgressDisplay = {
  label: 'Miscellaneous',
  value: 'miscellaneous',
}

const Header = styled('div')({
  display: 'flex',
  justifyContent: 'end',
})

const onOrdersBackButton = () => {
  const currentUrl = window.location.href
  const orderUuid = currentUrl.match(/property-questionnaire\/(.*)(?=\/)/)?.[1]

  window.onbeforeunload = null
  navigate(`${ORDERS_URL}/${orderUuid}`)
}

const getQuestionnaireStepConfigs = (props: {
  unauthed: boolean
  generateQuestionnaireLinkFunction: () => void
}) => {
  const { unauthed, generateQuestionnaireLinkFunction } = props
  const radioStakeholderText = unauthed ? 'Owner' : 'Me'
  const radioStakeholderNameText = unauthed ? "Owner's name" : 'My name'

  const leaseAndPropertyStep: StepConfig<
    PropertyQuestionnaireData,
    PropertyQuestionnaireFunctions,
    FormStepComponentProps,
    InjectedFormStepProps
  > = {
    ...basicProps('leaseAndProperty'),
    onBack: onOrdersBackButton,
    componentProps: {
      stepName: 'leaseAndProperty',
      progress: 'leaseAndProperty',
      backText: 'Return to Order',
      hideBack: unauthed,
      inputConfigs: [
        {
          name: 'leaseInfo',
          type: InputType.Section,
          props: {
            title: 'Lease Information',
            inputConfigs: [
              {
                name: 'hasLeaseInPlace',
                type: InputType.RadioSelect,
                defaultValue: '',
                required: true,
                props: {
                  label: 'Do you have a lease in place?',
                  labelSize: 'small',
                  row: true,
                  options: [
                    {
                      label: 'No',
                      value: 'false',
                    },
                    {
                      label: 'Yes',
                      value: 'true',
                    },
                  ],
                },
              },
              {
                name: 'leaseStartDate',
                type: InputType.DatePicker,
                required: true,
                renderIf: (watchedFields: any) =>
                  watchedFields.hasLeaseInPlace === 'true',
                gridItemProps: {
                  sm: 5,
                },
                props: {
                  label: 'Lease start',
                },
              },
              {
                name: 'leaseEndDateLabel',
                type: InputType.CustomComponent,
                renderIf: (watchedFields: any) =>
                  watchedFields.hasLeaseInPlace === 'true',
                gridItemProps: {
                  sm: 2,
                  sx: {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  },
                },
                props: {
                  component: (
                    <Typography
                      variant="h1"
                      color="moneyGreen.main"
                      pb={{ sm: '24px' }}
                    >
                      &#8594;
                    </Typography>
                  ),
                },
              },
              {
                name: 'leaseEndDate',
                type: InputType.DatePicker,
                required: true,
                renderIf: (watchedFields: any) =>
                  watchedFields.hasLeaseInPlace === 'true',
                gridItemProps: {
                  sm: 5,
                },
                props: {
                  label: 'Lease end',
                },
              },
            ],
          },
        },
        {
          name: 'tenantInfo',
          type: InputType.Multiform,
          renderIf: (watchedFields: any) =>
            watchedFields.hasLeaseInPlace === 'true',
          props: {
            title: 'Tenant Contact',
            maxForms: 4,
            minForms: 1,
            incrementText: '+ Add Tenant',
            decrementText: '- Remove Tenant',
            inputConfigs: [
              {
                name: 'tenantName',
                type: InputType.Text,
                required: true,
                props: {
                  label: 'Tenant name',
                  format: 'fullName',
                  placeholder: 'First and last name',
                },
              },
              {
                name: 'tenantEmail',
                type: InputType.Text,
                required: true,
                props: {
                  label: 'Tenant email',
                  format: 'email',
                  placeholder: 'email@flockhomes.com',
                },
                gridItemProps: {
                  sm: 7,
                },
              },
              {
                name: 'tenantPhoneNumber',
                type: InputType.Text,
                required: false,
                props: {
                  label: 'Tenant phone number',
                  format: 'phone',
                  placeholder: '(123) 456-7890',
                },
                gridItemProps: {
                  sm: 5,
                },
              },
            ],
          },
        },
        {
          name: 'propertyManagementInfo',
          type: InputType.Section,
          props: {
            title: 'Property Management',
            inputConfigs: [
              {
                name: 'hasPropertyManager',
                type: InputType.RadioSelect,
                defaultValue: '',
                required: true,
                gridItemProps: {
                  sm: 6,
                },
                props: {
                  label: 'Do you have a property manager?',
                  labelSize: 'small',
                  row: true,
                  options: [
                    {
                      label: 'No',
                      value: 'false',
                    },
                    {
                      label: 'Yes',
                      value: 'true',
                    },
                  ],
                },
              },
              {
                name: 'generateOneTimeLinkCard',
                renderIf: (watchedFields: any) =>
                  watchedFields.hasPropertyManager === 'true' &&
                  !watchedFields.unauthed,
                type: InputType.CustomComponent,
                gridItemProps: {
                  sm: 6,
                },
                props: {
                  component: !unauthed ? (
                    <Paper
                      elevation={8}
                      sx={{
                        borderRadius: '24px',
                        background: 'rgba(255, 255, 255, 0.25)',
                      }}
                    >
                      <Box
                        display="flex"
                        flexDirection="column"
                        gap="16px"
                        padding="24px"
                      >
                        <Typography variant="p2">
                          Don&apos;t know the answers?
                          <Box>
                            <TrackedLink
                              variant="p3"
                              onClick={generateQuestionnaireLinkFunction}
                            >
                              Email your property manager.
                            </TrackedLink>
                          </Box>
                        </Typography>
                      </Box>
                    </Paper>
                  ) : null,
                },
              },
              {
                name: 'canContactPropertyManager',
                type: InputType.RadioSelect,
                defaultValue: '',
                required: true,
                renderIf: (watchedFields: any) =>
                  watchedFields.hasPropertyManager === 'true',
                props: {
                  label: 'Can we contact the property manager?',
                  labelSize: 'small',
                  row: true,
                  options: [
                    {
                      label: 'No',
                      value: 'false',
                    },
                    {
                      label: 'Yes',
                      value: 'true',
                    },
                  ],
                },
              },
              {
                name: 'rentCollector',
                type: InputType.RadioSelect,
                defaultValue: '',
                required: true,
                renderIf: (watchedFields: any) =>
                  watchedFields.hasPropertyManager === 'true',
                props: {
                  label: 'Who collects rent?',
                  labelSize: 'small',
                  row: true,
                  options: [
                    {
                      label: radioStakeholderText,
                      value:
                        Core_PropertyQuestionnaireStakeholder.PropertyQuestionnaireStakeholderOwner,
                    },
                    {
                      label: 'Property Manager',
                      value:
                        Core_PropertyQuestionnaireStakeholder.PropertyQuestionnaireStakeholderPropertyManager,
                    },
                    {
                      label: 'Other',
                      value:
                        Core_PropertyQuestionnaireStakeholder.PropertyQuestionnaireStakeholderOther,
                    },
                  ],
                },
              },
              {
                name: 'securityDepositHolder',
                type: InputType.RadioSelect,
                defaultValue: '',
                required: true,
                renderIf: (watchedFields: any) =>
                  watchedFields.hasPropertyManager === 'true',
                props: {
                  label: 'Who holds the security deposit?',
                  labelSize: 'small',
                  row: true,
                  options: [
                    {
                      label: radioStakeholderText,
                      value:
                        Core_PropertyQuestionnaireStakeholder.PropertyQuestionnaireStakeholderOwner,
                    },
                    {
                      label: 'Property Manager',
                      value:
                        Core_PropertyQuestionnaireStakeholder.PropertyQuestionnaireStakeholderPropertyManager,
                    },
                    {
                      label: 'Other',
                      value:
                        Core_PropertyQuestionnaireStakeholder.PropertyQuestionnaireStakeholderOther,
                    },
                  ],
                },
              },
              {
                // this exists only so that we don't need to preload a file the user has already uploaded in a previous questionnaire unit
                name: 'notAlreadyUploaded',
                type: InputType.Checkbox,
                renderIf: (watchedFields: any) =>
                  watchedFields.alreadyUploaded &&
                  watchedFields.hasPropertyManager === 'true',
                props: {
                  label: 'Upload another property management agreement',
                },
              },
              {
                name: 'propertyManagementAgreement',
                type: InputType.FileUpload,
                required: true,
                renderIf: (watchedFields: any) =>
                  watchedFields.hasPropertyManager === 'true' &&
                  (!watchedFields.alreadyUploaded ||
                    watchedFields.notAlreadyUploaded),
                props: {
                  label: 'Please Upload the Property Management Agreement',
                  acceptDefaults: true,
                },
              },
            ],
          },
        },
        {
          name: 'logisticsInfo',
          type: InputType.Section,
          props: {
            title: 'Logistics',
            inputConfigs: [
              {
                name: 'rentalServiceDate',
                type: InputType.DatePicker,
                required: true,
                labelText: (
                  <Typography variant="p2">
                    When was this property put into service as a rental?
                  </Typography>
                ),
                props: {
                  disableFuture: true,
                  sx: {
                    '& .MuiInputBase-root': {
                      maxWidth: '160px',
                    },
                  },
                },
              },
              {
                name: 'hasWasherDryer',
                type: InputType.RadioSelect,
                defaultValue: '',
                required: true,
                props: {
                  label: 'Does the property have a washer/dryer?',
                  labelSize: 'small',
                  row: true,
                  options: [
                    {
                      label: 'No',
                      value: 'false',
                    },
                    {
                      label: 'Yes',
                      value: 'true',
                    },
                  ],
                },
              },
              {
                name: 'washerDryerOwner',
                type: InputType.RadioSelect,
                defaultValue: '',
                required: true,
                renderIf: (watchedFields: any) =>
                  watchedFields.hasWasherDryer === 'true',
                props: {
                  label: 'Who owns the washer/dryer?',
                  labelSize: 'small',
                  row: true,
                  options: [
                    {
                      label: radioStakeholderText,
                      value:
                        Core_PropertyQuestionnaireStakeholder.PropertyQuestionnaireStakeholderOwner,
                    },
                    {
                      label: 'Property Manager',
                      value:
                        Core_PropertyQuestionnaireStakeholder.PropertyQuestionnaireStakeholderPropertyManager,
                    },
                    {
                      label: 'Other',
                      value:
                        Core_PropertyQuestionnaireStakeholder.PropertyQuestionnaireStakeholderOther,
                    },
                  ],
                },
              },
              {
                name: 'garageCode',
                type: InputType.Text,
                labelText: (
                  <Typography variant="p2">
                    What is the garage door code?
                  </Typography>
                ),
                props: {
                  label: (
                    <Typography variant="p3" color="gray5.main">
                      Optional, leave blank if inapplicable.
                    </Typography>
                  ),
                  placeholder: '1234',
                  sx: {
                    '& .MuiInputBase-root': {
                      maxWidth: '160px',
                    },
                  },
                },
              },
              {
                name: 'mailboxDescription',
                type: InputType.Text,
                labelText: (
                  <Typography variant="p2">
                    Please describe the location and number of the mailbox
                  </Typography>
                ),
                props: {
                  placeholder: 'To the right of the driveway near the curb.',
                },
              },
              {
                name: 'ownerHasKeys',
                type: InputType.Checkbox,
                labelText: (
                  <Box display="flex" flexDirection="column">
                    <Typography variant="p2">
                      (Optional) Who currently has keys in addition to the
                      resident?
                    </Typography>
                    <Typography variant="p3" color="gray5.main">
                      If other is selected, please list their names and contact
                      information.
                    </Typography>
                  </Box>
                ),
                props: {
                  label: radioStakeholderText,
                },
              },
              {
                name: 'pmHasKeys',
                type: InputType.Checkbox,
                props: {
                  label: 'Property Manager',
                  sx: {
                    alignItems: 'center',
                  },
                },
              },
              {
                name: 'otherHasKeys',
                type: InputType.Checkbox,
                props: {
                  label: 'Other',
                },
              },
              {
                name: 'otherHasKeysNames',
                required: true,
                renderIf: (watchedFields: any) => watchedFields.otherHasKeys,
                type: InputType.Text,
                props: {
                  placeholder: 'Names of who else has keys.',
                },
              },
            ],
          },
        },
      ],
    },
  }

  const hoaAndUtilitiesStep: StepConfig<
    PropertyQuestionnaireData,
    PropertyQuestionnaireFunctions,
    FormStepComponentProps,
    InjectedFormStepProps
  > = {
    ...basicProps('hoaAndUtilities'),
    prerender: async (flowData: FlowData) => {
      window.scrollTo(0, 0)
      return { ...flowData }
    },
    onCompletion: async (
      flowData: Partial<PropertyQuestionnaireData>,
      functions: PropertyQuestionnaireFunctions
    ) => {
      await flattenPropertyQuestionnaireUnitData(
        flowData as PropertyQuestionnaireData,
        functions
      )
      return flowData as Partial<PropertyQuestionnaireData>
    },
    componentProps: {
      stepName: 'hoaAndUtilities',
      progress: 'hoaAndUtilities',
      ctaText: 'Submit',
      inputConfigs: [
        {
          name: 'hoaInformation',
          type: InputType.Section,
          props: {
            title: 'HOA',
            inputConfigs: [
              {
                name: 'hasHoa',
                type: InputType.RadioSelect,
                defaultValue: '',
                required: true,
                props: {
                  label: 'Does this property have an HOA?',
                  labelSize: 'small',
                  row: true,
                  options: [
                    {
                      label: 'No',
                      value: 'false',
                    },
                    {
                      label: 'Yes',
                      value: 'true',
                    },
                  ],
                },
              },
              {
                name: 'hoaName',
                type: InputType.Text,
                required: true,
                renderIf: (watchedFields: any) =>
                  watchedFields.hasHoa === 'true',
                props: {
                  label: 'HOA Name',
                  placeholder: 'Property HOA',
                },
              },
              {
                name: 'hoaEmail',
                type: InputType.Text,
                required: true,
                gridItemProps: {
                  sm: 7,
                },
                renderIf: (watchedFields: any) =>
                  watchedFields.hasHoa === 'true',
                props: {
                  label: 'HOA Email',
                  placeholder: 'email@email.com',
                  format: 'email',
                },
              },
              {
                name: 'hoaAccount',
                type: InputType.Text,
                required: true,
                gridItemProps: {
                  sm: 5,
                },
                renderIf: (watchedFields: any) =>
                  watchedFields.hasHoa === 'true',
                props: {
                  label: 'HOA Account Number',
                  placeholder: '1234567',
                  format: 'text',
                },
              },
              {
                name: 'hasHoaManagement',
                type: InputType.RadioSelect,
                defaultValue: '',
                renderIf: (watchedFields: any) =>
                  watchedFields.hasHoa === 'true',
                required: true,
                props: {
                  label: 'Does the HOA have a management company?',
                  labelSize: 'small',
                  row: true,
                  options: [
                    {
                      label: 'No',
                      value: 'false',
                    },
                    {
                      label: 'Yes',
                      value: 'true',
                    },
                  ],
                },
              },
              {
                name: 'hoaManagementName',
                type: InputType.Text,
                required: true,
                renderIf: (watchedFields: any) =>
                  watchedFields.hasHoaManagement === 'true' &&
                  watchedFields.hasHoa === 'true',
                props: {
                  label: 'HOA Management Name',
                  placeholder: 'HOA Manager',
                },
              },
              {
                name: 'hoaManagementEmail',
                type: InputType.Text,
                required: true,
                gridItemProps: {
                  sm: 7,
                },
                renderIf: (watchedFields: any) =>
                  watchedFields.hasHoaManagement === 'true' &&
                  watchedFields.hasHoa === 'true',
                props: {
                  label: 'HOA Management Email',
                  placeholder: 'email@email.com',
                  format: 'email',
                },
              },
              {
                name: 'hoaManagementPhone',
                type: InputType.Text,
                required: true,
                renderIf: (watchedFields: any) =>
                  watchedFields.hasHoaManagement === 'true' &&
                  watchedFields.hasHoa === 'true',
                gridItemProps: {
                  sm: 5,
                },
                props: {
                  label: 'HOA Management Phone',
                  placeholder: '(123) 456 7890',
                  format: 'phone',
                },
              },
              {
                name: 'hoaCoversUtilites',
                type: InputType.RadioSelect,
                defaultValue: '',
                required: true,
                renderIf: (watchedFields: any) =>
                  watchedFields.hasHoa === 'true',
                props: {
                  label: 'Does the HOA cover utilities?',
                  labelSize: 'small',
                  row: true,
                  options: [
                    {
                      label: 'No',
                      value: 'false',
                    },
                    {
                      label: 'Yes',
                      value: 'true',
                    },
                  ],
                },
              },
              {
                labelText: (
                  <Typography variant="p2">
                    Which utilities are covered?
                  </Typography>
                ),
                name: 'hoaCoversTrash',
                renderIf: (watchedFields: any) =>
                  watchedFields.hoaCoversUtilites === 'true' &&
                  watchedFields.hasHoa === 'true',
                type: InputType.Checkbox,
                props: {
                  label: 'Trash',
                },
              },
              {
                name: 'hoaCoversWater',
                renderIf: (watchedFields: any) =>
                  watchedFields.hoaCoversUtilites === 'true' &&
                  watchedFields.hasHoa === 'true',
                type: InputType.Checkbox,
                props: {
                  label: 'Water',
                },
              },
              {
                name: 'hoaCoversElectricity',
                renderIf: (watchedFields: any) =>
                  watchedFields.hoaCoversUtilites === 'true' &&
                  watchedFields.hasHoa === 'true',
                type: InputType.Checkbox,
                props: {
                  label: 'Electricity',
                },
              },
              {
                name: 'hoaCoversGas',
                renderIf: (watchedFields: any) =>
                  watchedFields.hoaCoversUtilites === 'true' &&
                  watchedFields.hasHoa === 'true',
                type: InputType.Checkbox,
                props: {
                  label: 'Gas',
                },
              },
            ],
          },
        },
        {
          name: 'utilitiesInformation',
          type: InputType.Section,
          props: {
            title: 'Utilities',
            inputConfigs: [
              {
                type: InputType.CustomComponent,
                name: 'utilitiesLabel',
                props: {},
                labelText: (
                  <Typography variant="p2">
                    Please provide the trash utility information.
                  </Typography>
                ),
              },
              {
                name: 'trashProviderName',
                type: InputType.Text,
                required: true,
                gridItemProps: {
                  sm: 7,
                },
                props: {
                  label: 'Company Name',
                  placeholder: 'Trash company name',
                },
              },
              {
                name: 'trashProviderPhoneNumber',
                type: InputType.Text,
                required: true,
                gridItemProps: {
                  sm: 5,
                },
                props: {
                  label: 'Company Phone Number',
                  placeholder: '(123) 456 7890',
                  format: 'phone',
                },
              },
              {
                name: 'trashBillStakeholder',
                type: InputType.RadioSelect,
                defaultValue: '',
                required: true,
                props: {
                  label: 'The trash bill is in:',
                  labelSize: 'small',
                  row: true,
                  options: [
                    {
                      label: (
                        <Typography variant="p2">
                          {radioStakeholderNameText}
                        </Typography>
                      ),
                      value:
                        Core_PropertyQuestionnaireStakeholder.PropertyQuestionnaireStakeholderOwner,
                    },
                    {
                      label: (
                        <Typography variant="p2">
                          Property manager&apos;s name
                        </Typography>
                      ),
                      value:
                        Core_PropertyQuestionnaireStakeholder.PropertyQuestionnaireStakeholderPropertyManager,
                    },
                    {
                      label: (
                        <Typography variant="p2">Tenant&apos;s name</Typography>
                      ),
                      value:
                        Core_PropertyQuestionnaireStakeholder.PropertyQuestionnaireStakeholderOther,
                    },
                  ],
                },
              },
              {
                type: InputType.CustomComponent,
                name: 'waterUtilitiesLabel',
                props: {},
                labelText: (
                  <Typography variant="p2">
                    Please provide the water utility information.
                  </Typography>
                ),
              },
              {
                name: 'waterProviderName',
                type: InputType.Text,
                required: true,
                gridItemProps: {
                  sm: 7,
                },
                props: {
                  label: 'Company Name',
                  placeholder: 'Water company name',
                },
              },
              {
                name: 'waterProviderPhoneNumber',
                type: InputType.Text,
                required: true,
                gridItemProps: {
                  sm: 5,
                },
                props: {
                  label: 'Company Phone Number',
                  placeholder: '(123) 456 7890',
                  format: 'phone',
                },
              },
              {
                name: 'waterBillStakeholder',
                type: InputType.RadioSelect,
                defaultValue: '',
                required: true,
                props: {
                  label: 'The water bill is in:',
                  labelSize: 'small',
                  row: true,
                  options: [
                    {
                      label: (
                        <Typography variant="p2">
                          {radioStakeholderNameText}
                        </Typography>
                      ),
                      value:
                        Core_PropertyQuestionnaireStakeholder.PropertyQuestionnaireStakeholderOwner,
                    },
                    {
                      label: (
                        <Typography variant="p2">
                          Property manager&apos;s name
                        </Typography>
                      ),
                      value:
                        Core_PropertyQuestionnaireStakeholder.PropertyQuestionnaireStakeholderPropertyManager,
                    },
                    {
                      label: (
                        <Typography variant="p2">Tenant&apos;s name</Typography>
                      ),
                      value:
                        Core_PropertyQuestionnaireStakeholder.PropertyQuestionnaireStakeholderOther,
                    },
                  ],
                },
              },
              {
                type: InputType.CustomComponent,
                name: 'utilitiesLabel',
                props: {},
                labelText: (
                  <Typography variant="p2">
                    Please provide the electricity utility information.
                  </Typography>
                ),
              },
              {
                name: 'electricityProviderName',
                type: InputType.Text,
                required: true,
                gridItemProps: {
                  sm: 7,
                },
                props: {
                  label: 'Company Name',
                  placeholder: 'Electricity company name',
                },
              },
              {
                name: 'electricityProviderPhoneNumber',
                type: InputType.Text,
                required: true,
                gridItemProps: {
                  sm: 5,
                },
                props: {
                  label: 'Company Phone Number',
                  placeholder: '(123) 456 7890',
                  format: 'phone',
                },
              },
              {
                name: 'electricityBillStakeholder',
                type: InputType.RadioSelect,
                defaultValue: '',
                required: true,
                props: {
                  label: 'The electricity bill is in:',
                  labelSize: 'small',
                  row: true,
                  options: [
                    {
                      label: (
                        <Typography variant="p2">
                          {radioStakeholderNameText}
                        </Typography>
                      ),
                      value:
                        Core_PropertyQuestionnaireStakeholder.PropertyQuestionnaireStakeholderOwner,
                    },
                    {
                      label: (
                        <Typography variant="p2">
                          Property manager&apos;s name
                        </Typography>
                      ),
                      value:
                        Core_PropertyQuestionnaireStakeholder.PropertyQuestionnaireStakeholderPropertyManager,
                    },
                    {
                      label: (
                        <Typography variant="p2">Tenant&apos;s name</Typography>
                      ),
                      value:
                        Core_PropertyQuestionnaireStakeholder.PropertyQuestionnaireStakeholderOther,
                    },
                  ],
                },
              },
              {
                type: InputType.CustomComponent,
                name: 'utilitiesLabel',
                props: {},
                labelText: (
                  <Typography variant="p2">
                    Please provide the gas utility information.
                  </Typography>
                ),
              },
              {
                name: 'gasProviderName',
                type: InputType.Text,
                required: true,
                gridItemProps: {
                  sm: 7,
                },
                props: {
                  label: 'Company Name',
                  placeholder: 'Gas company name',
                },
              },
              {
                name: 'gasProviderPhoneNumber',
                type: InputType.Text,
                required: true,
                gridItemProps: {
                  sm: 5,
                },
                props: {
                  label: 'Company Phone Number',
                  placeholder: '(123) 456 7890',
                  format: 'phone',
                },
              },
              {
                name: 'gasBillStakeholder',
                type: InputType.RadioSelect,
                defaultValue: '',
                required: true,
                props: {
                  label: 'The gas bill is in:',
                  labelSize: 'small',
                  row: true,
                  options: [
                    {
                      label: (
                        <Typography variant="p2">
                          {radioStakeholderNameText}
                        </Typography>
                      ),
                      value:
                        Core_PropertyQuestionnaireStakeholder.PropertyQuestionnaireStakeholderOwner,
                    },
                    {
                      label: (
                        <Typography variant="p2">
                          Property manager&apos;s name
                        </Typography>
                      ),
                      value:
                        Core_PropertyQuestionnaireStakeholder.PropertyQuestionnaireStakeholderPropertyManager,
                    },
                    {
                      label: (
                        <Typography variant="p2">Tenant&apos;s name</Typography>
                      ),
                      value:
                        Core_PropertyQuestionnaireStakeholder.PropertyQuestionnaireStakeholderOther,
                    },
                  ],
                },
              },
            ],
          },
        },
      ],
    },
  }

  return [leaseAndPropertyStep, hoaAndUtilitiesStep]
}

const questionnaireMiscellaneousStep: StepConfig<
  PropertyQuestionnaireData,
  PropertyQuestionnaireFunctions,
  FormStepComponentProps,
  InjectedFormStepProps
> = {
  ...basicProps('miscellaneousInfo'),
  onBack: onOrdersBackButton,
  onCompletion: async (
    flowData: FlowData,
    functions: PropertyQuestionnaireFunctions
  ) => {
    await flattenPropertyQuestionnaireMiscData(flowData, functions)
    return flowData
  },
  componentProps: {
    stepName: 'miscellaneousInfo',
    progress: 'miscellaneous',
    backText: 'Return to Order',
    ctaText: 'Submit',
    inputConfigs: [
      {
        name: 'costBasisInformation',
        type: InputType.Section,
        props: {
          title: 'Cost Basis',
          inputConfigs: [
            {
              name: 'costBasisLabel',
              type: InputType.CustomComponent,
              props: {
                component: null,
              },
              labelText: (
                <Typography variant="p2">
                  Please provide information about the cost basis of your
                  property (when you purchased the house).
                </Typography>
              ),
            },
            {
              name: 'costBasisYear',
              type: InputType.Text,
              required: true,
              gridItemProps: {
                sm: 6,
              },
              props: {
                label: 'Cost Basis Year',
                format: 'year',
                placeholder: '2001',
              },
            },
            {
              name: 'costBasisMonth',
              type: InputType.Dropdown,
              required: true,
              gridItemProps: {
                sm: 6,
              },
              defaultValue: '',
              props: {
                InputLabelProps: {
                  shrink: true,
                },
                label: 'Cost Basis Month',
                options: MONTHS.map((month) => ({
                  label: month,
                  value: month,
                })),
              },
            },
            {
              name: 'knowsCostBasis',
              type: InputType.RadioSelect,
              defaultValue: '',
              required: true,
              props: {
                label:
                  'Do you know the current cost basis amount of your property?',
                labelSize: 'small',
                row: true,
                options: [
                  {
                    label: 'No',
                    value: 'false',
                  },
                  {
                    label: 'Yes',
                    value: 'true',
                  },
                ],
              },
            },
            {
              name: 'costBasisAmount',
              type: InputType.Text,
              required: true,
              gridItemProps: {
                sm: 6,
              },
              renderIf: (watchedFields: any) =>
                watchedFields.knowsCostBasis === 'true',
              props: {
                label: 'Cost Basis Amount',
                format: 'dollars',
                placeholder: '$530,000',
              },
            },
            {
              name: 'hiddenSpacer',
              type: InputType.CustomComponent,
              props: {},
              gridItemProps: {
                sm: 6,
                visibility: 'hidden',
              },
              renderIf: (watchedFields: any) =>
                watchedFields.knowsCostBasis === 'true',
            },
            {
              name: 'depreciationSchedule',
              type: InputType.FileUpload,
              required: true,
              props: {
                label:
                  'Please upload the depreciation schedule from your most recent 1040/ Schedule E',
                acceptDefaults: true,
              },
            },
          ],
        },
      },
      {
        name: 'financialsInformation',
        type: InputType.Section,
        props: {
          title: 'Financials',
          inputConfigs: [
            {
              name: 'cashOutRefinance',
              type: InputType.RadioSelect,
              defaultValue: '',
              required: true,
              props: {
                label:
                  'Have you taken cash out during a refinancing of this property?',
                labelSize: 'small',
                row: true,
                options: [
                  {
                    label: 'No',
                    value: 'false',
                  },
                  {
                    label: 'Yes',
                    value: 'true',
                  },
                ],
              },
            },
            {
              name: 'cashOutRefinancingDescription',
              type: InputType.Text,
              required: true,
              renderIf: (watchedFields: any) =>
                watchedFields.cashOutRefinance === 'true',
              props: {
                label: 'Describe the cash out refinancing.',
              },
            },
            {
              name: 'specialFireDistrict',
              type: InputType.RadioSelect,
              defaultValue: '',
              required: true,
              props: {
                label: `Is your property located in a special fire district where you're required to pay fire dues?`,
                labelSize: 'small',
                row: true,
                options: [
                  {
                    label: 'No',
                    value: 'false',
                  },
                  {
                    label: 'Yes',
                    value: 'true',
                  },
                ],
              },
            },
            {
              name: 'specialFireDistrictDuesAmount',
              type: InputType.Text,
              required: true,
              renderIf: (watchedFields: any) =>
                watchedFields.specialFireDistrict === 'true',
              labelText: (
                <Typography variant="p2">
                  What is the annual amount due of these fire dues?
                </Typography>
              ),
              props: {
                format: 'dollars',
                placeholder: '$600',
                sx: {
                  '& .MuiInputBase-root': {
                    maxWidth: '160px',
                  },
                },
              },
            },
            {
              name: 'specialFireDisctrictDuesBill',
              type: InputType.FileUpload,
              required: true,
              renderIf: (watchedFields: any) =>
                watchedFields.specialFireDistrict === 'true',
              props: {
                label: 'Please upload your most recent bill for fire dues.',
                acceptDefaults: true,
              },
            },
            {
              name: 'solarPanelsInstalled',
              type: InputType.RadioSelect,
              defaultValue: '',
              required: true,
              props: {
                label: 'Does your property have solar panels installed?',
                labelSize: 'small',
                row: true,
                options: [
                  {
                    label: 'No',
                    value: 'false',
                  },
                  {
                    label: 'Yes',
                    value: 'true',
                  },
                ],
              },
            },
            {
              name: 'solarPanelsOwned',
              type: InputType.RadioSelect,
              defaultValue: '',
              required: true,
              renderIf: (watchedFields: any) =>
                watchedFields.solarPanelsInstalled === 'true',
              props: {
                label: 'Are the solar panels rented or owned?',
                labelSize: 'small',
                row: true,
                options: [
                  {
                    label: 'Rented',
                    value: 'false',
                  },
                  {
                    label: 'Owned',
                    value: 'true',
                  },
                ],
              },
            },
            {
              name: 'solarPanelAccountStatement',
              type: InputType.FileUpload,
              renderIf: (watchedFields: any) =>
                watchedFields.solarPanelsInstalled === 'true',
              labelText: (
                <Typography variant="p2">
                  Please upload your solar panel account statement.
                </Typography>
              ),
              props: {
                label: (
                  <Typography variant="p3" sx={{ color: 'gray5.main' }}>
                    Optional, screenshots are also acceptable.
                  </Typography>
                ),
                acceptDefaults: true,
              },
            },
            {
              name: 'specialAssessmentLiensOrImprovementBonds',
              type: InputType.RadioSelect,
              defaultValue: '',
              required: true,
              props: {
                label:
                  'Are there any special assessment liens or improvement bonds on your property?',
                labelSize: 'small',
                row: true,
                options: [
                  {
                    label: 'No',
                    value: 'false',
                  },
                  {
                    label: 'Yes',
                    value: 'true',
                  },
                ],
              },
            },
            {
              name: 'specialAssessmentLiensOrImprovementBondsAmount',
              type: InputType.Text,
              required: true,
              renderIf: (watchedFields: any) =>
                watchedFields.specialAssessmentLiensOrImprovementBonds ===
                'true',
              labelText: (
                <Typography variant="p2">
                  How many assessment liens/bonds do you have?
                </Typography>
              ),
              props: {
                format: 'dollars',
                placeholder: '$6,000',
                sx: {
                  '& .MuiInputBase-root': {
                    maxWidth: '160px',
                  },
                },
              },
            },
            {
              name: 'specialAssessmentLiensOrImprovementBondsDocuments',
              type: InputType.FileUpload,
              renderIf: (watchedFields: any) =>
                watchedFields.specialAssessmentLiensOrImprovementBonds ===
                'true',
              labelText: (
                <Typography variant="p2">
                  Please upload documents that show who is paying for each lien
                  or bond.
                </Typography>
              ),
              props: {
                label: (
                  <Typography variant="p3" sx={{ color: 'gray5.main' }}>
                    If documents are unavailable, include company information.
                  </Typography>
                ),
                acceptDefaults: true,
              },
            },
            {
              name: 'lienOrBondCompanyInfo',
              type: InputType.Multiform,
              renderIf: (watchedFields: any) =>
                watchedFields.specialAssessmentLiensOrImprovementBonds ===
                'true',
              props: {
                title: 'Lien or Bond Company',
                maxForms: 2,
                minForms: 1,
                incrementText: '+ Add Secondary Contact Information',
                decrementText: '- Remove Secondary Contact Information',
                inputConfigs: [
                  {
                    name: 'specialAssessmentLiensOrImprovementBondsCompanyName',
                    required: true,
                    type: InputType.Text,
                    props: {
                      label: 'Company Name',
                      placeholder: 'Company Name',
                    },
                  },
                  {
                    name: 'specialAssessmentLiensOrImprovementBondsCompanyEmail',
                    required: true,
                    type: InputType.Text,
                    gridItemProps: {
                      sm: 7,
                    },
                    props: {
                      label: 'Company Email',
                      placeholder: 'email@email.com',
                      format: 'email',
                    },
                  },
                  {
                    name: 'specialAssessmentLiensOrImprovementBondsCompanyPhone',
                    required: true,
                    type: InputType.Text,
                    gridItemProps: {
                      sm: 5,
                    },
                    props: {
                      label: 'Company Phone',
                      placeholder: '(123) 456-7890',
                      format: 'phone',
                    },
                  },
                  {
                    name: 'specialAssessmentLiensOrImprovementBondsCompanyAddress',
                    required: true,
                    type: InputType.Text,
                    gridItemProps: {
                      sm: 7,
                    },
                    props: {
                      label: 'Company Address',
                      placeholder: '12345 Main Street, Denver, CO 80220',
                    },
                  },
                  {
                    name: 'specialAssessmentLiensOrImprovementBondsCompanyAccountNumber',
                    required: true,
                    type: InputType.Text,
                    gridItemProps: {
                      sm: 5,
                    },
                    props: {
                      label: 'Account no.',
                      placeholder: '12345',
                    },
                  },
                ],
              },
            },
            {
              name: 'specialAssessmentLiensOrImprovementBondsConsent',
              renderIf: (watchedFields: any) =>
                watchedFields.specialAssessmentLiensOrImprovementBonds ===
                'true',
              required: true,
              type: InputType.Checkbox,
              props: {
                label: (
                  <Typography variant="p2" sx={{ color: 'gray8.main' }}>
                    I authorize that the lien and bond information is complete
                    and accurate
                  </Typography>
                ),
              },
            },
            {
              name: 'hasSurvey',
              type: InputType.RadioSelect,
              defaultValue: '',
              required: true,
              props: {
                label: 'Do you have a survey?',
                labelSize: 'small',
                row: true,
                options: [
                  {
                    label: 'No',
                    value: 'false',
                  },
                  {
                    label: 'Yes',
                    value: 'true',
                  },
                ],
              },
            },
            {
              name: 'surveyDocuments',
              type: InputType.FileUpload,
              required: true,
              renderIf: (watchedFields: any) =>
                watchedFields.hasSurvey === 'true',
              labelText: (
                <Typography variant="p2">
                  Please upload a copy of the survey.
                </Typography>
              ),
              props: {
                label: (
                  <Typography variant="p3" sx={{ color: 'gray5.main' }}>
                    If documents are unavailable, include company information.
                  </Typography>
                ),
                acceptDefaults: true,
              },
            },
            {
              name: 'hasSurveyImprovements',
              type: InputType.RadioSelect,
              defaultValue: '',
              required: true,
              renderIf: (watchedFields: any) =>
                watchedFields.hasSurvey === 'true',
              props: {
                label:
                  'Have improvements been made since the preparation of the last survey?',
                labelSize: 'small',
                row: true,
                options: [
                  {
                    label: 'No',
                    value: 'false',
                  },
                  {
                    label: 'Yes',
                    value: 'true',
                  },
                ],
              },
            },
            {
              name: 'surveyImprovements',
              required: true,
              renderIf: (watchedFields: any) =>
                watchedFields.hasSurveyImprovements === 'true' &&
                watchedFields.hasSurvey === 'true',
              type: InputType.Text,
              props: {},
              labelText: (
                <Typography variant="p2">
                  Please list improvements made since the survey.
                </Typography>
              ),
            },
            {
              name: 'ownersPolicyTitleInsurance',
              type: InputType.RadioSelect,
              defaultValue: '',
              required: true,
              props: {
                label:
                  "Did you obtain a copy of an owner's policy of title insurance when your purchased?",
                labelSize: 'small',
                row: true,
                options: [
                  {
                    label: 'No',
                    value: 'false',
                  },
                  {
                    label: 'Yes',
                    value: 'true',
                  },
                ],
              },
            },
            {
              name: 'ownersPolicyTitleInsuranceDocuments',
              type: InputType.FileUpload,
              required: true,
              renderIf: (watchedFields: any) =>
                watchedFields.ownersPolicyTitleInsurance === 'true',
              props: {
                label:
                  "Please upload a copy of the owner's policy of title insurance.",
                acceptDefaults: true,
              },
            },
          ],
        },
      },
      {
        name: 'authorizationInformation',
        type: InputType.Section,
        props: {
          title: 'Authorization for Information Release',
          inputConfigs: [
            {
              name: 'hasMortgage',
              type: InputType.RadioSelect,
              defaultValue: '',
              required: true,
              props: {
                label: 'Do you have a mortgage?',
                labelSize: 'small',
                row: true,
                options: [
                  {
                    label: 'No',
                    value: 'false',
                  },
                  {
                    label: 'Yes',
                    value: 'true',
                  },
                ],
              },
            },
            {
              name: 'mortgageLenderInfo',
              type: InputType.Multiform,
              renderIf: (watchedFields: any) =>
                watchedFields.hasMortgage === 'true',
              props: {
                title: 'Mortgage',
                maxForms: 2,
                minForms: 1,
                incrementText: '+ Add Second Mortgage',
                decrementText: '- Remove Second Mortgage',
                inputConfigs: [
                  {
                    renderIf: (watchedFields: any) =>
                      watchedFields.hasMortgage === 'true',
                    name: 'lenderName',
                    type: InputType.Text,
                    required: true,
                    gridItemProps: {
                      sm: 8,
                    },
                    props: {
                      label: 'Lender name',
                      placeholder: 'Company Name',
                    },
                  },
                  {
                    renderIf: (watchedFields: any) =>
                      watchedFields.hasMortgage === 'true',
                    name: 'lenderPhoneNumber',
                    type: InputType.Text,
                    required: true,
                    gridItemProps: {
                      sm: 4,
                    },
                    props: {
                      label: 'Lender phone no.',
                      placeholder: '(123) 456-7890',
                      format: 'phone',
                    },
                  },
                  {
                    renderIf: (watchedFields: any) =>
                      watchedFields.hasMortgage === 'true',
                    name: 'loanNumber',
                    type: InputType.Text,
                    required: true,
                    gridItemProps: {
                      sm: 4,
                    },
                    props: {
                      label: 'Loan number',
                      placeholder: '12345',
                    },
                  },
                  {
                    renderIf: (watchedFields: any) =>
                      watchedFields.hasMortgage === 'true',
                    name: 'loanAmount',
                    type: InputType.Text,
                    required: true,
                    gridItemProps: {
                      sm: 4,
                    },
                    props: {
                      label: 'Loan amount',
                      placeholder: '$12345',
                      format: 'dollars',
                    },
                  },
                  {
                    name: 'mortgageLastPaymentDate',
                    type: InputType.DatePicker,
                    required: true,
                    renderIf: (watchedFields: any) =>
                      watchedFields.hasMortgage === 'true',
                    gridItemProps: {
                      sm: 4,
                    },
                    props: {
                      label: 'Last payment date',
                    },
                  },
                  {
                    renderIf: (watchedFields: any) =>
                      watchedFields.hasMortgage === 'true',
                    name: 'loanSsn',
                    type: InputType.Text,
                    required: true,
                    gridItemProps: {
                      sm: 6,
                    },
                    props: {
                      label: 'SSN associated with loan',
                      format: 'ssn',
                      placeholder: '123-45-6789',
                    },
                  },
                  {
                    renderIf: (watchedFields: any) =>
                      watchedFields.hasMortgage === 'true',
                    name: 'lenderAdditionalInfo',
                    type: InputType.Text,
                    gridItemProps: {
                      sm: 6,
                    },
                    props: {
                      label: 'Additional info',
                      placeholder: 'Notes',
                    },
                  },
                ],
              },
            },
            {
              name: 'mortgageInformationAuthorization',
              renderIf: (watchedFields: any) =>
                watchedFields.hasMortgage === 'true',
              required: true,
              type: InputType.Checkbox,
              props: {
                label: (
                  <Typography variant="p2" sx={{ color: 'gray8.main' }}>
                    I authorize that the mortgage information is complete and
                    accurate
                  </Typography>
                ),
              },
            },
            {
              name: 'usedAsPrimaryResidence',
              type: InputType.RadioSelect,
              defaultValue: '',
              required: true,
              props: {
                label:
                  'I owned and used the residence as my principal residence in periods totaling to 2 years or more during the last 5 years.',
                labelSize: 'small',
                row: true,
                options: [
                  {
                    label: 'False',
                    value: 'false',
                  },
                  {
                    label: 'True',
                    value: 'true',
                  },
                ],
              },
            },
            {
              name: 'soldOrExchangeAnotherPrincipalResidence',
              type: InputType.RadioSelect,
              defaultValue: '',
              required: true,
              props: {
                label:
                  'I have not sold or exchanged another principal residence in the last 2 years.',
                labelSize: 'small',
                row: true,
                options: [
                  {
                    label: 'False',
                    value: 'false',
                  },
                  {
                    label: 'True',
                    value: 'true',
                  },
                ],
              },
            },
            {
              name: 'businessOrRentalAfterDate',
              type: InputType.RadioSelect,
              defaultValue: '',
              required: true,
              props: {
                label:
                  'I (or my spouse or former spouse, if I was married anytime after May 6, 1997) have not used any portion of the residence for business or rental purposes after May 6, 1997.',
                labelSize: 'small',
                row: true,
                options: [
                  {
                    label: 'False',
                    value: 'false',
                  },
                  {
                    label: 'True',
                    value: 'true',
                  },
                ],
              },
            },
            {
              name: 'saleOrExchangeAmountDetails',
              type: InputType.RadioSelect,
              defaultValue: '',
              required: true,
              props: {
                label: (
                  <>
                    One of the following is true:
                    <br />
                    1. The sale or exchange is of the entire residence for
                    $250,000 or less.
                    <br />
                    2. I am married, the sale or exchange is of the entire
                    residence and for $500,000 or less, and the gain on the sale
                    or exchange of the entire residence is $250,000 or less.
                    <br />
                    3. I am married, the sale or exchange is of the entire
                    residence and for $500,000 or less, and (a) I intend to file
                    a joint return for the year of the sale or exchange, (b) my
                    spouse also used the residence as his or her principal
                    residence for periods totaling 2 years or more during the
                    last 5 years, and (c) my spouse also has not sold or
                    exchanged another principal residence during the last 2
                    years.
                  </>
                ),
                labelSize: 'small',
                row: true,
                options: [
                  {
                    label: 'False',
                    value: 'false',
                  },
                  {
                    label: 'True',
                    value: 'true',
                  },
                ],
              },
            },
            {
              name: 'contributed1031',
              type: InputType.RadioSelect,
              defaultValue: '',
              required: true,
              props: {
                label:
                  'During the last 5 years, did you contribute the residence to a 1031 exchange?',
                labelSize: 'small',
                row: true,
                options: [
                  {
                    label: 'No',
                    value: 'false',
                  },
                  {
                    label: 'Yes',
                    value: 'true',
                  },
                ],
              },
            },
            {
              name: 'acquired1031',
              type: InputType.RadioSelect,
              defaultValue: '',
              required: true,
              props: {
                label:
                  'During the last 5 years, did you acquire the residence from a 1031 exchange?',
                labelSize: 'small',
                row: true,
                options: [
                  {
                    label: 'No',
                    value: 'false',
                  },
                  {
                    label: 'Yes',
                    value: 'true',
                  },
                ],
              },
            },
          ],
        },
      },
      {
        name: 'ownersAffadavitInformation',
        type: InputType.Section,
        props: {
          title: "Owner's Affidavit",
          inputConfigs: [
            {
              name: 'otherPartiesCanClaimPossession',
              type: InputType.RadioSelect,
              defaultValue: '',
              required: true,
              props: {
                label:
                  'As of this date, are there parties in possession or anyone that can claim future rights of possession?',
                labelSize: 'small',
                row: true,
                options: [
                  {
                    label: 'No',
                    value: 'false',
                  },
                  {
                    label: 'Yes',
                    value: 'true',
                  },
                ],
              },
            },
            {
              renderIf: (watchedFields: any) =>
                watchedFields.otherPartiesCanClaimPossession === 'true',
              name: 'otherPartiesCanClaimPossessionDescription',
              type: InputType.Text,
              required: true,
              props: {
                label: 'Please describe:',
              },
            },
            {
              name: 'pendingLitigations',
              type: InputType.RadioSelect,
              defaultValue: '',
              required: true,
              props: {
                label:
                  'As of this date, are there pending litigation or disputes regarding the property lines, boundaries or encroachments?',
                labelSize: 'small',
                row: true,
                options: [
                  {
                    label: 'No',
                    value: 'false',
                  },
                  {
                    label: 'Yes',
                    value: 'true',
                  },
                ],
              },
            },
            {
              renderIf: (watchedFields: any) =>
                watchedFields.pendingLitigations === 'true',
              name: 'pendingLitigationsDescription',
              type: InputType.Text,
              required: true,
              props: {
                label: 'Please describe:',
              },
            },
            {
              name: 'unpaidRepairsOrAlterations',
              type: InputType.RadioSelect,
              defaultValue: '',
              required: true,
              props: {
                label:
                  'Have there been repairs or alterations have been made to the property in the last 90 days that have NOT been paid in full?',
                labelSize: 'small',
                row: true,
                options: [
                  {
                    label: 'No',
                    value: 'false',
                  },
                  {
                    label: 'Yes',
                    value: 'true',
                  },
                ],
              },
            },
            {
              renderIf: (watchedFields: any) =>
                watchedFields.unpaidRepairsOrAlterations === 'true',
              name: 'unpaidRepairsOrAlterationsDescription',
              type: InputType.Text,
              required: true,
              props: {
                label: 'Please describe:',
              },
            },
            {
              name: 'futureAssessmentsOrTaxes',
              type: InputType.RadioSelect,
              defaultValue: '',
              required: true,
              props: {
                label:
                  'Have you received any notices from a taxing authority or municipality for future assessments or taxes which could result in a lien on said property',
                labelSize: 'small',
                row: true,
                options: [
                  {
                    label: 'No',
                    value: 'false',
                  },
                  {
                    label: 'Yes',
                    value: 'true',
                  },
                ],
              },
            },
            {
              renderIf: (watchedFields: any) =>
                watchedFields.futureAssessmentsOrTaxes === 'true',
              name: 'futureAssessmentsOrTaxesDescription',
              type: InputType.Text,
              required: true,
              props: {
                label: 'Please describe:',
              },
            },
            {
              name: 'uccFinancingStatements',
              type: InputType.RadioSelect,
              defaultValue: '',
              required: true,
              props: {
                label:
                  "Are you aware of any financing statements (UCC's) that may affect real or personal property included in this transaction",
                labelSize: 'small',
                row: true,
                options: [
                  {
                    label: 'No',
                    value: 'false',
                  },
                  {
                    label: 'Yes',
                    value: 'true',
                  },
                ],
              },
            },
            {
              renderIf: (watchedFields: any) =>
                watchedFields.uccFinancingStatements === 'true',
              name: 'uccFinancingStatementsDescription',
              type: InputType.Text,
              required: true,
              props: {
                label: 'Please describe:',
              },
            },
            {
              name: 'otherEncumberances',
              type: InputType.RadioSelect,
              defaultValue: '',
              required: true,
              props: {
                label:
                  'Except as set forth in the Commitment, are there any mortgages, Deeds of Trust, judgments, tax liens, easements or other encumbrances which still affect the real property?',
                labelSize: 'small',
                row: true,
                options: [
                  {
                    label: 'No',
                    value: 'false',
                  },
                  {
                    label: 'Yes',
                    value: 'true',
                  },
                ],
              },
            },
            {
              renderIf: (watchedFields: any) =>
                watchedFields.otherEncumberances === 'true',
              name: 'otherEncumberancesDescription',
              type: InputType.Text,
              required: true,
              props: {
                label: 'Please describe:',
              },
            },
            {
              name: 'pendingBankruptcy',
              type: InputType.RadioSelect,
              defaultValue: '',
              required: true,
              props: {
                label:
                  'As of this date, are there any pending proceedings for bankruptcy or receivership which have been initiated by or for you?',
                labelSize: 'small',
                row: true,
                options: [
                  {
                    label: 'No',
                    value: 'false',
                  },
                  {
                    label: 'Yes',
                    value: 'true',
                  },
                ],
              },
            },
            {
              renderIf: (watchedFields: any) =>
                watchedFields.pendingBankruptcy === 'true',
              name: 'pendingBankruptcyDescription',
              type: InputType.Text,
              required: true,
              props: {
                label: 'Please describe:',
              },
            },
            {
              name: 'outstandingProjects',
              type: InputType.RadioSelect,
              defaultValue: '',
              required: true,
              props: {
                label:
                  'To the best of your actual knowledge and belief, have there been any of the following: construction projects such as new structures, additional buildings, rooms, garages, swimming pools or other permanent improvements or fixtures; changes in the location of boundary fences or boundary walls; construction projects on immediately adjoining property(ies) which encroach on the Property; conveyances, replattings, easement grants and/or easement dedications (such as a utility line) by any party affecting the Property?',
                labelSize: 'small',
                row: true,
                options: [
                  {
                    label: 'No',
                    value: 'false',
                  },
                  {
                    label: 'Yes',
                    value: 'true',
                  },
                ],
              },
            },
            {
              renderIf: (watchedFields: any) =>
                watchedFields.outstandingProjects === 'true',
              name: 'outstandingProjectsDescription',
              type: InputType.Text,
              required: true,
              props: {
                label: 'Please describe:',
              },
            },
            // {
            //   required: true,
            //   type: InputType.Checkbox,
            //   props: {
            //     label: (
            //       <Typography variant="p2" sx={{ color: 'gray8.main' }}>
            //         I/We make the statements in this Affidavit to induce
            //         Endpoint to issue its extended form policy(s) of title
            //         insurance relative to said real property. I/We further agree
            //         to indemnify and defend Endpoint, and First American Title
            //         Insurance Company, or its agent, and save you harmless from
            //         any loss you may sustain as a result of issuing your Policy
            //         of Title Insurance based upon the above representations, and
            //         agree that, in case you or your insured is named in a suit
            //         based upon facts which are inconsistent to the above
            //         representations, we will pay, at our own expense, any
            //         resulting judgment and all costs and attorney&apos; fees
            //         associated therewith.
            //       </Typography>
            //     ),
            //   },
            // },
          ],
        },
      },
    ],
  },
}

const PropertyQuestionnairePage = (
  props: RouteComponentProps & {
    unauthed?: boolean
    orderUuid?: string
    propertyContributionUuid?: string
    oneTimeLink?: string
  }
) => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [currentOrder, setCurrentOrder] = useState<Core_OrderV2>(
    {} as Core_OrderV2
  )
  const [unitOptions, setUnitOptions] = useState<
    { label: string; value: string; metadata: JSX.Element }[]
  >([])
  const [currentQuestionnaire, setCurrentQuestionnaire] =
    useState<JSX.Element>()
  const [showChangeUnitModal, setShowChangeUnitModal] = useState(false)
  const [changeUnitTarget, setChangeUnitTarget] = useState('0')
  const [currentUnit, setCurrentunit] = useState(0)
  const { notify } = useSnackbar()
  const { unauthed, orderUuid, propertyContributionUuid, oneTimeLink } = props
  const { refetch } = useQuery(
    unauthed
      ? InvestorPropertyQuestionnaireGetOrderV2PropertyQuestionnairesUnauthedDocument
      : InvestorPropertyQuestionnaireGetOrderV2PropertyQuestionnairesDocument,
    {
      variables: {
        input: {
          orderUuid,
        },
      },
      skip: true,
    }
  )

  const [generatePropertyQuestionnaireV2Link] = useMutation(
    InvestorGeneratePropertyQuestionnaireV2OneTimeLinkDocument
  )
  const [updatePropertyQuestionnaireTask] = useMutation(
    InvestorUpdatePropertyQuestionnaireTaskDocument
  )
  const [updatePropertyQuestionnaireTaskUnauthed] = useMutation(
    InvestorUpdatePropertyQuestionnaireTaskUnauthedDocument
  )

  const [evictPropertyQuestionnaireV2OneTimeLink] = useMutation(
    InvestorEvictPropertyQuestionnaireV2OneTimeLinkDocument
  )
  const [createAddressDocument] = useMutation(
    InvestorCreateAddressDocumentDocument
  )
  const functions: PropertyQuestionnaireFunctions = useMemo(
    () => ({
      updatePropertyQuestionnaireTask: unauthed
        ? updatePropertyQuestionnaireTaskUnauthed
        : updatePropertyQuestionnaireTask,
      createAddressDocument,
    }),
    [
      createAddressDocument,
      unauthed,
      updatePropertyQuestionnaireTask,
      updatePropertyQuestionnaireTaskUnauthed,
    ]
  )

  const fetchOrder = useCallback(async () => {
    try {
      const result = await refetch()
      const { data: orderData, error: orderError } = result
      if (orderError) {
        setError(true)
      }
      setCurrentOrder(
        (
          (orderData as any)?.[
            `getOrderV2${
              unauthed ? 'Unauthed' : ''
            }` as keyof InvestorPropertyQuestionnaireGetOrderV2PropertyQuestionnairesUnauthedQuery
          ] as Core_GetOrderV2UnauthedResponse
        ).order as Core_OrderV2
      )
    } catch (e) {
      setError(true)
    }
  }, [refetch, unauthed])

  const generatePropertyQuestionnaireLinkHelper = useCallback(
    async (address: Core_ValidatedAddress) => {
      try {
        const result = await generatePropertyQuestionnaireV2Link({
          variables: {
            input: {
              orderUuid,
              propertyContributionUuid,
            },
          },
        })
        const { link: oneTimeLinkUrl } = result.data
          ?.generatePropertyQuestionnaireV2OneTimeLink as Core_GeneratePropertyQuestionnaireV2OneTimeLinkResponse
        const formattedUrl = `${FLOCK_INVESTOR_URL}${SHARE_PROPERTY_QUESTIONNAIRE_URL}/${oneTimeLinkUrl}`
        window.location.href = `mailto:?subject=${formatAddressString(
          address
        )}, ${formatCityStateZip(
          address
        )}&body=Hi,%0D%0A%0D%0ACan you please help me complete this property questionnaire for ${formatAddressString(
          address
        )}, ${formatCityStateZip(
          address
        )}? You can click the following link to complete the document: ${formattedUrl}`
      } catch (e) {
        notify(
          'Oops, we had an issue generating a link for your property manager. Please reach out to our team if the issue persists.'
        )
      }
    },
    [
      generatePropertyQuestionnaireV2Link,
      notify,
      orderUuid,
      propertyContributionUuid,
    ]
  )

  useEffect(() => {
    fetchOrder()
  }, [fetchOrder])

  useEffect(() => {
    try {
      setLoading(true)
      if (currentOrder) {
        const currentPropertyContribution =
          currentOrder?.propertyContributions?.find(
            (propertyContribution) =>
              propertyContribution.uuid === propertyContributionUuid
          )

        const questionnaireTask =
          currentPropertyContribution?.propertyQuestionnaireProcess[0]
        const contributionAddressUuid = currentPropertyContribution?.addressUuid

        if (
          !currentPropertyContribution ||
          !questionnaireTask ||
          !contributionAddressUuid
        ) {
          return
        }

        if (
          currentPropertyContribution?.propertyQuestionnaireProcess[0]
            .status === Core_OrderV2TaskStatus.OrderV2TaskStatusCompleted
        ) {
          navigate(`${ORDERS_URL}/${orderUuid}`)
        }

        const completedAddresses =
          (
            questionnaireTask?.resolutionData
              ?.data as Core_OrderV2TaskResolutionData_DataOneof_PropertyQuestionnaireCompletedTaskResolutionData
          )?.propertyQuestionnaireCompletedTaskResolutionData?.perUnitData || []

        // if the route is unauthed and all the unit questions are complete, reroute to landing
        if (unauthed) {
          if (
            (currentPropertyContribution?.address?.units?.length !== 0 &&
              completedAddresses.length ===
                currentPropertyContribution?.address?.units?.length) ||
            (currentPropertyContribution?.address?.units?.length === 0 &&
              completedAddresses.length === 1)
          ) {
            try {
              evictPropertyQuestionnaireV2OneTimeLink({
                variables: {
                  input: {
                    link: oneTimeLink as string,
                  },
                },
              })
            } finally {
              navigate(`${FLOCK_LANDING_URL}`)
            }
          } else {
            setLoading(false)
          }
        } else {
          setLoading(false)
        }

        const options: {
          label: string
          value: string
          completed: boolean
          metadata: JSX.Element
          disabled?: boolean
        }[] = []

        const currentAddress = currentPropertyContribution.address!

        if (currentPropertyContribution?.address?.units?.length !== 0) {
          // this is the case of multiunit with some units incomplete
          // we prefill hoa and property management info
          const questionnaireTaskResolutionDataUnit =
            (
              questionnaireTask.resolutionData
                ?.data as Core_OrderV2TaskResolutionData_DataOneof_PropertyQuestionnaireCompletedTaskResolutionData
            )?.propertyQuestionnaireCompletedTaskResolutionData?.perUnitData ||
            []
          let prefillLeaseAndPropertyData: { [key: string]: any }
          let prefillHOAData: { [key: string]: any }
          if (questionnaireTaskResolutionDataUnit?.length > 0) {
            // we prefill the data from the first unit that is returned
            const prefillCurrentUnit = questionnaireTaskResolutionDataUnit[0]
            prefillLeaseAndPropertyData = {
              alreadyUploaded:
                prefillCurrentUnit?.propertyManager?.propertyManagementAgreement
                  ?.length,
              hasPropertyManager: unauthed
                ? 'true'
                : prefillCurrentUnit.hasPropertyManager?.toString(),
              canContactPropertyManager:
                prefillCurrentUnit.propertyManager?.contactPropertyManager?.toString(),
              rentCollector:
                prefillCurrentUnit.propertyManager?.rentCollector?.toString(),
              securityDepositHolder:
                prefillCurrentUnit.propertyManager?.securityDepositHolder?.toString(),
            }

            prefillHOAData = {
              hasHoa: prefillCurrentUnit.hasHoa?.toString(),
              hoaName: prefillCurrentUnit.hoa?.name,
              hoaEmail: prefillCurrentUnit.hoa?.email,
              hoaAccount: prefillCurrentUnit.hoa?.accountNumber,
              hoaCoversUtilites:
                prefillCurrentUnit.hoa?.coversUtilities?.toString(),
              hoaCoversTrash: prefillCurrentUnit.hoa?.coveredUtilites?.find(
                (value: any) =>
                  value ===
                  Core_PropertyQuestionnaireUtility.PropertyQuestionnaireUtilityTrash
              ),
              hoaCoversWater: prefillCurrentUnit.hoa?.coveredUtilites?.find(
                (value: any) =>
                  value ===
                  Core_PropertyQuestionnaireUtility.PropertyQuestionnaireUtilityWater
              ),
              hoaCoversElectricity:
                prefillCurrentUnit.hoa?.coveredUtilites?.find(
                  (value: any) =>
                    value ===
                    Core_PropertyQuestionnaireUtility.PropertyQuestionnaireUtilityElectricity
                ),
              hoaCoversGas: prefillCurrentUnit.hoa?.coveredUtilites?.find(
                (value: any) =>
                  value ===
                  Core_PropertyQuestionnaireUtility.PropertyQuestionnaireUtilityGas
              ),

              hasHoaManagement:
                prefillCurrentUnit.hoa?.hasManagementCompany?.toString(),
              hoaManagementName:
                prefillCurrentUnit.hoa?.managementCompany?.name,
              hoaManagementEmail:
                prefillCurrentUnit.hoa?.managementCompany?.email,
              hoaManagementPhone:
                prefillCurrentUnit.hoa?.managementCompany?.phone,

              trashProviderName: prefillCurrentUnit.utilities?.trashCompanyName,
              trashProviderPhoneNumber:
                prefillCurrentUnit.utilities?.trashCompanyPhone,
              trashBillStakeholder:
                prefillCurrentUnit.utilities?.trashCompanyStakeholder,

              gasProviderName: prefillCurrentUnit.utilities?.gasCompanyName,
              gasProviderPhoneNumber:
                prefillCurrentUnit.utilities?.gasCompanyPhone,
              gasBillStakeholder:
                prefillCurrentUnit.utilities?.gasCompanyStakeholder,

              electricityProviderName:
                prefillCurrentUnit.utilities?.electricityCompanyName,
              electricityProviderPhoneNumber:
                prefillCurrentUnit.utilities?.electricityCompanyPhone,
              electricityBillStakeholder:
                prefillCurrentUnit.utilities?.electricityCompanyStakeholder,

              waterProviderName: prefillCurrentUnit.utilities?.waterCompanyName,
              waterProviderPhoneNumber:
                prefillCurrentUnit.utilities?.waterCompanyPhone,
              waterBillStakeholder:
                prefillCurrentUnit.utilities?.waterCompanyStakeholder,
            }
          }
          ;(
            currentPropertyContribution?.address?.units?.filter(
              (address: any) => address !== null
            ) as Core_ValidatedAddress[]
          )?.forEach((address: Core_ValidatedAddress, idx) => {
            const completedUnit =
              completedAddresses.findIndex(
                (completed) => completed.addressUuid === address.uuid
              ) !== -1
            options.push({
              label: `${getAddressStreet(address)}, ${address.unit}`,
              value: String(idx),
              // completed field will be used later when we decide to prefill this entire form for already completed questionnaires
              completed: completedUnit,
              disabled: completedUnit,
              metadata: (
                <StepperForm
                  key={String(idx)}
                  title="Property Questionnaire"
                  initialData={{
                    propertyContributionUuid,
                    taskUuid: questionnaireTask.taskUuid,
                    orderUuid,
                    addressUuid: address.uuid,
                    leaseAndProperty: {
                      ...prefillLeaseAndPropertyData,
                    },
                    hoaAndUtilities: {
                      ...prefillHOAData,
                    },
                    oneTimeLink,
                    unauthed,
                  }}
                  stepConfigs={getQuestionnaireStepConfigs({
                    unauthed: !!unauthed,
                    generateQuestionnaireLinkFunction: () => {
                      generatePropertyQuestionnaireLinkHelper(
                        currentPropertyContribution.address!
                      )
                    },
                  })}
                  progressDisplaySteps={progressDisplaySteps}
                  functions={functions}
                  useStepComponentError
                  useStepComponentLoading
                />
              ),
            })
          })
        } else {
          // for single family, we still push this to options
          // TODO: for this and multifamily, do form prefills if they've already completed the form rather than disable
          const completedProperty = completedAddresses.length === 1
          options.push({
            label: `${getAddressStreet(currentAddress)}`,
            value: String(0),
            completed: completedProperty,
            disabled: completedProperty,
            metadata: (
              <StepperForm
                title="Property Questionnaire"
                initialData={{
                  propertyContributionUuid,
                  taskUuid: questionnaireTask.taskUuid,
                  orderUuid,
                  addressUuid: currentAddress.uuid,
                  oneTimeLink,
                  unauthed,
                }}
                stepConfigs={getQuestionnaireStepConfigs({
                  unauthed: !!unauthed,
                  generateQuestionnaireLinkFunction: () => {
                    generatePropertyQuestionnaireLinkHelper(
                      currentPropertyContribution.address!
                    )
                  },
                })}
                progressDisplaySteps={progressDisplaySteps}
                functions={functions}
                useStepComponentError
                useStepComponentLoading
              />
            ),
          })
        }

        // always have miscellaneous as an option in the dropdown, but disable it until all units are completed since this actually sends the task completed payload
        // don't show miscellaneous if the page is unauthed
        const whichOption = options.findIndex((option) => !option.completed)
        if (!unauthed) {
          options.push({
            label: 'Miscellaneous',
            value: String(options.length),
            completed: false,
            disabled: whichOption !== -1,
            metadata: (
              <StepperForm
                title="Property Questionnaire"
                initialData={{
                  propertyContributionUuid,
                  taskUuid: questionnaireTask.taskUuid,
                  orderUuid,
                  addressUuid: currentAddress.uuid,
                }}
                stepConfigs={[questionnaireMiscellaneousStep]}
                progressDisplaySteps={[
                  questionnaireMiscellaneousProgressDisplay,
                ]}
                functions={functions}
                useStepComponentError
                useStepComponentLoading
              />
            ),
          })
        }
        const questionnaireOption =
          whichOption === -1 ? options.length - 1 : whichOption
        setUnitOptions(options)
        setCurrentQuestionnaire(options[questionnaireOption].metadata)
        setCurrentunit(questionnaireOption)
      }
    } catch (e) {
      setError(true)
    }
  }, [
    currentOrder,
    evictPropertyQuestionnaireV2OneTimeLink,
    functions,
    generatePropertyQuestionnaireLinkHelper,
    oneTimeLink,
    orderUuid,
    propertyContributionUuid,
    unauthed,
  ])

  if (error) {
    return <ErrorCardPage />
  }
  if (loading) {
    return <LoadingCardPage text="Loading questionnaire..." />
  }

  const changeUnitModal = (value: string) => {
    setShowChangeUnitModal(true)
    setChangeUnitTarget(value)
  }

  return (
    <>
      <ChangeUnitModal
        isOpen={showChangeUnitModal}
        onClose={() => {
          setShowChangeUnitModal(false)
        }}
        targetUnit={() => {
          setCurrentQuestionnaire(
            unitOptions.find((option) => option.value === changeUnitTarget)
              ?.metadata!
          )
          setShowChangeUnitModal(false)
          setCurrentunit(+changeUnitTarget)
        }}
      />
      <PageContainer
        title="FLOCK | Property Questionnaire"
        trackingName="property-questionnaire"
      >
        <PageWrapper>
          <Header>
            <Box>
              {unitOptions.length > 0 && (
                <Dropdown
                  options={unitOptions}
                  onChange={(event) => {
                    changeUnitModal(event.target.value)
                  }}
                  value={unitOptions[currentUnit].value}
                  sx={{
                    padding: '12px',
                  }}
                />
              )}
            </Box>
          </Header>
          <Grid container spacing={4} height="auto">
            {currentQuestionnaire}
          </Grid>
        </PageWrapper>
      </PageContainer>
    </>
  )
}

PropertyQuestionnairePage.defaultProps = {
  orderUuid: undefined,
  propertyContributionUuid: undefined,
  oneTimeLink: undefined,
  unauthed: false,
}

export default PropertyQuestionnairePage
